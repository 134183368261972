.connexion-title {
  font-family: Syne;
  font-size: 45px;
  color: var(--blue-tah-color);
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: var(--purple-tah-color);
}

.connexion-label-input {
  font-family: Rota;
  font-size: 110%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #282828;
}

.connexion-form-input {
  border: solid 2px var(--blue-tah-color);
  border-radius: 0.25rem;
  background-color: #e6f0ff;
  font-family: Rota;
  height: 35px;
}

.connexion-register {
  text-decoration: underline;
  font-family: Rota;
  color: var(--blue-tah-color);
}

.connexion-button {
  border: solid 3px var(--blue-tah-color);
  background-color: var(--blue-tah-color);
  border-radius: 0;
  font-family: Syne;
  font-size: 17px;
  color: #e6f0ff;
}

.connexion-button:hover, .connexion-button:focus, .connexion-button:active {
  background-color: var(--purple-tah-color);
  border-color: var(--purple-tah-color);
  color: #e6f0ff;
}

.connexion-button-img {
  height: 14%;
  width: 14%;
}

.connexion-container {
  width: 100%;
  height: calc(100vh - 84px);
}

.rounded-border {
  border: solid 2px;
  border-radius: 5px;
}

@media (max-width: 991px) {
  .connexion-title {
    font-size: 37px;
  }
}

.connexion-error {
  width: 275px;
  border: solid 1px #86181d;
  border-radius: 0;
  background-color: #ffdce0;
  margin: 0 auto;
  font-family: Rota;
  font-size: 95%;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: #86181d;
}
