.square {
    position: relative;
    width: 75%;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    border-radius: 2.5px !important;
}

@media (max-width: 991px) {
    .square {
        width: 75% !important;
    }
}

@media (max-width: 767px) {
    .square {
        width: 85% !important;
    }
}

@media (max-width: 575px) {
    .square {
        width: 85% !important;
    }
}

@media (max-width: 575px) {
    .content {
        border-width: thin;
    }
}
