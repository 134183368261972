.condition-modal {
    background: #fff;
    display: flex !important;
    align-items: center;
    height: 95%;
    overflow: scroll;
}

.condition-modal .condition-modal--header {
    border:0;
}
