html, body, #root, .App, .App-content, .window-size {
  height: 100vh !important;
  width: 100%;
  min-width: 0 !important;
}

:root {
  /* Set the deafult values */
  --selected-primary-color: var(--light-primary-color);
  --selected-disabled-color: var(--light-disabled-color);

  /* Set the 'light' theme */
  --blue-tah-color: #91AFE5;
  --purple-tah-color: #927BDB;
  --red-tah-color: #ea1616;
  --rose-tah-color: #E879B3;
  --black-tah-color: #000000;
  --green-tah-color: #51B292;
  --yellow-tah-color: #FFEE99;
  --white-purple-tah-color: #ece9f6;
  --warning-tah-color: #ffc107;

  /* Transparent colors */
  --tr-blue-tah-color: rgba(145, 175, 229, 0.85);
  --tr-purple-tah-color: rgba(146, 123, 219, 0.85);
  --tr-red-tah-color: rgba(234, 22, 22, 0.85);
  --tr-green-tah-color: rgba(81, 178, 146, 0.85);
  --tr-black-tah-color: #0000004D;
  --tr-light-gray-tah-color: rgba(60,155,236,.05);

  /* Darker colors */
  --drk-blue-tah-color: #497ad7;
  --drk-purple-tah-color: #6b4fcc;
  --drk-red-tah-color: #e70000;
  --drk-black-tah-color: #000000d9;
  --drk-green-tah-color: #24c488;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.police-gilroy-font{
  font-family: Syne;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: var(--purple-tah-color);
}

.police-gte-font{
  font-family: Rota;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--purple-tah-color);
}
#container-arrow > div:first-child:after {
  content:'\e803';
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "fontello";
  width: 1em;
  margin-right: .2em;
  text-align: center;
  color: var(--purple-tah-color);
  pointer-events: none;
}

.container-arrow-left > div:first-child:after {
  content:'\e803';
  position: absolute;
  left: 5px;
  top: 37%;
  transform: translateY(-50%);
  font-family: "fontello";
  width: 1em;
  margin-right: .2em;
  text-align: center;
  color: var(--purple-tah-color);
  pointer-events: none;
}

._1cgu0CqbPSvU9G_sHmk1wv {
  color: var(--purple-tah-color);
}

.box-borderless{
  height: 35px;
  border: none !important;
  background-color: #e6f0ff;
  border-radius: 5px;
}

.text-overflow-dots {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-font{
  height: 35px;
  border: solid 1px var(--purple-tah-color) !important;
  background-color: #e6f0ff;
  border-radius: 0;
}

.input-font:focus{
  outline: 2px solid #8665db;
  box-shadow: 0px 0px 0px 2px #b8a3f0;
  background-color: #e6f0ff;
}

/* HIDE SCROLLBAR in MultiSelect*/
._2iA8p44d0WZ-WqRBGcAuEV::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  position: relative;
}

.scrollbar-hidden::-webkit-scrollbar:vertical {
  width: 10px;
}

.scrollbar-hidden::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scrollbar-hidden::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

/* HIDE SEARCH INPUT OF 'ReactMultiSelectCheckboxes' */
.no-search-input-select > .css-1jllj6i-control{
  display: none;
}

.no-search-input-select > .css-1r4vtzz{
  box-shadow: none;
}

.css-xld5m{
  outline: none !important
}

.select-disable{
  pointer-events: none;
  /*background-color: rgba(74, 37, 170, 0.3) !important;*/
  opacity: .5;
  border-color: var(--purple-tah-color) !important;
}

.underline-title-font{
  border-bottom: 5px solid;
}

/* Custom datepicker */
.react-datepicker{
  display: flex !important;
}
.popper-container-custom  {
  width: fit-content;
  height: 100%;
}
.react-datepicker__input-container{
  width: 100%;
}
/*END*/

.sizing-adapted{
  width: 600px;
}

.custom-btn-lightblue{
  color: var(--blue-tah-color);
  border: solid 2px var(--blue-tah-color);
  background-color: #e6f0ff;
  height: 35px;
}

.custom-btn-green:hover{
  background-color: #1fb23b;
  color: #ffffff !important;
}

.custom-btn-red:hover{
  background-color: #ea1616;
  color: #ffffff !important;
}

.hover-anim:hover {
  background-color: var(--purple-tah-color) !important;
  color: #ffffff !important;
}

.hover-anim-lightblue:hover{
  background-color: var(--blue-tah-color) !important;
  color: #ffffff !important;
}

.date-input, .date-input:active{
  height: 35px;
  border: solid 1px var(--purple-tah-color);
  background-color: #e6f0ff;
  border-radius: 0;
  outline: none !important;
}

.color-282828{
  color: #282828 !important;
}

.unselectable, .unselectable:hover{
  pointer-events: none;
}

.invalid-feedback-custom{
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.oc-button-empty-black {
  border: solid 3px #282828;
  border-radius: 0;
  font-family: Syne;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #282828;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.45);
  padding: 12px 20px 12px 20px;
  text-transform: uppercase;
}

.oc-button-empty-black:hover, .oc-button-empty-black:focus, .oc-button-empty-black:active {
  background-color: #282828;
  border-color: #282828;
  color: #ffffff;
}

.rounded-border {
  border: solid 2px;
  border-radius: 5px;
}

.title {
  font-family: Syne;
  font-size: 40px;
  color: var(--blue-tah-color);
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: var(--purple-tah-color);
}

.alert-payment {
  cursor: pointer;
}

.sos-icon::before {
  content: "SOS";
  margin: auto;
}
.sos-icon {
  background-color: var(--red-tah-color);
  border-radius: 30px;
  height: 45px;
  width: 45px;
  align-content: center;
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
  -webkit-box-pack: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
  display: -webkit-box!important;
  display: -ms-flexbox!important;
  display: flex!important;
  color: #ffffff;
  font-weight: bold;
}

#search_input:active, #search_input:focus{ /* Remove outline of multiSelector */
  outline: none !important
}

#purple-selector > div > i{
  color: var(--purple-tah-color);
}

@media (max-width: 768px) {
  #title{font-size: 20px !important}
  .underline-title-font{border-bottom: 4px solid}
}

@media (min-width: 576px){
  .fontSize-responsive{font-size: 2vw}
}

@media (max-width: 576px){
  .fontSize-responsive{font-size: 2.5vw}
  .underline-title-font{border-bottom: 3px solid}
  .css-xld5m{padding: 0px}
  .sos-icon {
    height: 35px !important;
    width: 35px !important;
  }
}

/* BREAKPOINTS */

/* SM breakpoint */
@media (min-width: 576px) {
  /* CUSTOM WIDTHS */
  .w-sm-50 { width: 50%!important; }
  .w-sm-100 { width: 100%!important; }
}

/* MD breakpoint*/
@media (min-width: 768px) {
  /* CUSTOM WIDTHS */
  .w-md-50 { width: 50%!important; }
  .w-md-100 { width: 100%!important; }
}

/* LG breakpoint */
@media (min-width: 992px) {
  /* CUSTOM WIDTHS */
  .w-lg-50 { width: 50%!important; }
  .w-lg-100 { width: 100%!important; }
}
