.logo-mobile {
    width: 90%;
    height: 75%;
}

.menu-hamburger-btn {
    border: 1px solid transparent;
    background-color: transparent;
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
 }

.logout-img,
.menu-hamburger-img {
    width: 30px;
    height: auto;
}

.menu-burger-tutor-opt,
.menu-burger-customer-opt,
.logout {
    background-color: transparent;
}

.menu-burger-tutor-opt:hover {
    background-color: var(--purple-tah-color);
    border-color: var(--purple-tah-color);
}

.menu-burger-customer-opt:hover {
    background-color: var(--blue-tah-color);
    border-color: var(--blue-tah-color);
}
