.mobile-stepper {
    background-color: var(--tr-light-gray-tah-color) !important;
}

.mobile-stepper > button:disabled {
    color: var(--tr-blue-tah-color);
}

.mobile-stepper > button:not(disabled) {
    color: var(--blue-tah-color);
    outline: none;
}

.step-title {
  font-family: Syne;
  font-weight: bold;
}

.tutorial-step-highlight {
    position: relative !important;
    z-index: 1301 !important;
    border: 3px solid var(--warning-tah-color) !important;
    border-radius: .25rem;
}

.tutorial-step-highlight > #scroll-container > *, 
.tutorial-step-highlight > :not(#scroll-container) , 
.tutorial-step-highlight > *[class*="active"] {
    pointer-events: none;
}

.enabled {
    pointer-events: auto !important;
}