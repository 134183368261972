.form {
    background-color: #ffffff;
    top: 50%;
    transform: translateY(-55%);
}

.form-container {
    border: 1px solid var(--white-purple-tah-color);
    border-radius: 0.25rem;
    animation: fadein .5s;
    -moz-animation: fadein .5s; /* Firefox */
    -webkit-animation: fadein .5s; /* Safari et Chrome */
    -o-animation: fadein .5s; /* Opera */
}