.navbar-header {
  height: 70px;
  border: solid var(--blue-tah-color);
  border-width: 1px 1px 0px 1px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: 0px 7.5px 5px var(--tr-black-tah-color);
}

.navbar-header.customer {
  background-color: var(--blue-tah-color);
}

.navbar-header {
  background-color: var(--purple-tah-color);
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

.logout-img {
  width: 30px;
  height: auto;
}

a.navbar-link:hover {
  text-decoration: none;
  color: #ffffff
}

span.navbar-link {
  position: relative;
  font-family: Syne;
  font-size: 1.5em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: uppercase;
}

span.navbar-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--yellow-tah-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

span.navbar-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

span.navbar-link-active:before {
  visibility: visible;
  transform: scaleX(1);
}
