.menu-burger-tutor-opt, .menu-burger-customer-opt, .menu-burger-logout-opt {
  padding: .5rem !important;
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  outline: none !important; 
}

.menu-burger-logout-opt:hover {
background-color: var(--tr-red-tah-color) !important;
border-color: transparent !important;
}

.menu-burger-tutor-opt:hover{
background-color: var(--tr-purple-tah-color) !important;
border-color: transparent !important;
}

.menu-burger-customer-opt:hover {
background-color: var(--tr-blue-tah-color) !important;
border-color: transparent !important;
}